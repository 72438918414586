import { render, staticRenderFns } from "./LeaderBoard.vue?vue&type=template&id=6f495a9f&scoped=true"
import script from "./LeaderBoard.vue?vue&type=script&lang=js"
export * from "./LeaderBoard.vue?vue&type=script&lang=js"
import style0 from "./LeaderBoard.vue?vue&type=style&index=0&id=6f495a9f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f495a9f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme4MobileMobHeader: require('/app/components/Theme4/Mobile/MobHeader.vue').default})
